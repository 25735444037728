import { IDAColumn } from 'types/IDADetailsList';

export const feedbackFields: IDAColumn[] = [
  {
    key: 'when',
    fieldName: 'when',
    name: 'When',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'status',
    fieldName: 'status',
    name: 'Status',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'recipient_name',
    fieldName: 'recipient_name',
    name: 'Recipient',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'provider_name',
    fieldName: 'provider_name',
    name: 'Provider',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'requester_name',
    fieldName: 'requester_name',
    name: 'Requester',
    minWidth: 100,
    maxWidth: 100,
  }
];
