/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  CommandButton,
  MessageBar,
  MessageBarType,
  TextField,
  Stack,
  DatePicker,
  ComboBox,
} from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { Paper } from '../Paper';

import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useToast } from 'providers/ToastContextProvider';
import { ICourse } from 'types/ICourse';
import { useCreateCourse, useDeleteCourse, useCourseSingle, useUpdateCourse } from 'hooks/useCourses';
import { useMembersDropdownOptions } from '../../hooks/useMembers';



const getFormErrors = (form: ICourse): any => {
  const errors: any = {};

  if (!form.level1) {
    errors.level1 = 'Level 1 is required.';
  }
  if (!form.level2) {
    errors.level2 = 'Level 2 is required.';
  }
  if (!form.course) {
    errors.course = 'Course is required.';
  }
  if (!form.wiki_url) {
    errors.wiki_url = 'Wiki URL is required.';
  }

  return errors;
};

export const FeedbackFormPage: React.FC = () => {
  const history = useHistory();
  const { feedbackId } = useParams<any>();
  const { courseId } = useParams<any>();
  const { course } = useCourseSingle(courseId);
  const { setToastSuccessMessage } = useToast();

  const [data, setData] = useState(course);
  
  const { setBreadcrumbs } = useBreadcrumbs();
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState('');

  const memberOptions = useMembersDropdownOptions();



  const { create, isLoading: isCreating } = useCreateCourse({
    onSuccess: () => {
      history.push(`/managecourses`);
      setToastSuccessMessage('Course has been created.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to create a new course.'
      ),
  });
  const { update, isLoading: isUpdating } = useUpdateCourse({
    onSuccess: () => {
      history.push(`/managecourses`);
      setToastSuccessMessage('Course has been updated.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to update the course.'
      ),
  });
  const { delete: deleteSkill, isLoading: isDeleting } =
    useDeleteCourse({
      onSuccess: () => {
        history.push(`/managecourses`);
      },
      onError: () =>
        setGeneralError(
          'An error occurred while trying to delete the course.'
        ),
    });

  const isSubmitting = useMemo(
    () => isCreating || isUpdating || isDeleting,
    [isCreating, isUpdating, isDeleting]
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'feedback',
        text: 'Feedback',
        onClick: () => history.push('/feedback'),
      },
      ...(courseId
        ? [{ key: 'course', text: data?.course ?? 'Unknown' }]
        : [{ key: 'new', text: 'New' }]),
    ]);
  }, [history, data, courseId]);

  const onSubmit = async () => {
    const form = data ?? {};
    const errors = getFormErrors(form);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const payload = form;
    if (!form.id) {
      create({ payload });
    } else {
      update({ courseId, payload  });
    }
  };

  const onDelete = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      deleteSkill(courseId);
    }
  }, [data]);

  return (
    <Page>
      <CommandBar>
        {data?.id && (
          <>
            <CommandButton
              iconProps={{ iconName: 'Undo' }}
              text="Discard Changes"
              onClick={() => history.push(`/managecourses`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Delete' }}
              text="Delete Feedback"
              onClick={onDelete}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Save' }}
              text="Update Feedback"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
        {!data?.id && (
          <>
            <CommandButton
              iconProps={{ iconName: 'Cancel' }}
              text="Cancel"
              onClick={() => history.push(`/managecourses`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Save' }}
              text="Create Feedback"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>{data?.id ? 'Edit Feedback' : 'Create New Feedback'}</h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <ComboBox
                  label="Project Lead"
                  options={memberOptions}
                  // selectedKey={formState.project_lead_id}
                  allowFreeform
                  autoComplete="on"
                  onChange={(_: any, value: any) => {
                    if (!value) return;
                    // setFormState((state) => ({
                    //   ...state,
                    //   project_lead_id: parseInt(value.key, 10),
                    // }));
                  }}
                  errorMessage={errors.project_lead_id}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
              <DatePicker
                  label="When"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  // onSelectDate={(date) =>
                  // }
                  isRequired={false}
                  // value={
                  // }
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Context"
                  value={data?.course ? data.course + '' : ''}
                  onChange={(_: any, course: any) =>
                    setData((state: ICourse) => ({ ...state, course }))
                  }
                  errorMessage={errors.course}
                  disabled={isSubmitting}
                  multiline
                  autoAdjustHeight
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Feedback"
                  value={data?.wiki_url ? data.wiki_url + '' : ''}
                  onChange={(_: any, wiki_url: any) =>
                    setData((state: ICourse) => ({ ...state, wiki_url }))
                  }
                  errorMessage={errors.wiki_url}
                  disabled={isSubmitting}
                  multiline
                  autoAdjustHeight
                />
              </Stack.Item>
            </Stack>
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default FeedbackFormPage;
